@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body,
html,
#root {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: black;
}

code {
  font-family: 'Inter', sans-serif;
}

.form-without-scrollbar {
  overflow: auto;
  height: 100%;
}

.card-scroll-height {
  max-height: calc(100vh - 175px);
}

.react-datepicker-wrapper {
  width: 100%;

  color: black !important;
  display: flex !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  border-radius: var(--chakra-radii-sm) !important;
  flex-wrap: wrap !important;
  padding: 0px 0px 0px 0px !important;
  overflow: hidden !important;
  /* border-radius: 6px !important; */
  min-height: var(--chakra-sizes-8) !important;
  
}

.react-datepicker-wrapper > div > input {
  width: 100% !important;
  color: black !important;
  border: 1px solid var(--chakra-colors-brandLight) !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  border-radius: 6px !important;
  flex-wrap: wrap !important;
  padding: 0px 10px !important;
  overflow: hidden !important;
  min-height: var(--chakra-sizes-8) !important;
  font-size: 14px !important;
}

.react-datepicker-wrapper > div > input:hover {
  border: 1px solid var(--chakra-colors-gray-300) !important;
}

.react-datepicker-wrapper > div > input:focus-visible {
  border: 1px solid var(--chakra-colors-brandSecondary) !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0px !important;
  right: 8px !important;
  top: 8px !important;
  fill: orange !important;
}

.clear-button {
  position: absolute;
  padding: 0px !important;
  right: 44px !important;
  top: 0px !important;
  cursor: pointer;
}

.react-datepicker__close-icon::after {
  background-color: var(--chakra-colors-brand) !important;
}

.react-datepicker-wrapper > div > input.is-invalid {
  border: 2px solid #e53e3e !important;
  /* box-shadow: 0 0 0 1px #E53E3E !important; */
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.react-datepicker__day--selected {
  background-color: var(--chakra-colors-brand) !important;
  color: white !important;
}

.css-48wy07 {
  font-size: 12px;
  width: 225px !important;
  background-color: var(--chakra-colors-brandLight) !important;
  color: var(--chakra-colors-gray) !important;
}

.css-14ogjxt{
  display: none !important;
}


